.nav-categories {
  background-color: #216691;
  width: 100%;
  font-family: "Happy Fox Condensed";
  margin-bottom: -3px;
  border-radius: 0;

  .active {
    a {
      color: $link-color-hover !important;
      background-color: transparent !important;
    }
  }
  ul {
    width: 90%;
    padding-left: 5%;
  }
  li {
    background-color: transparent !important;
    text-align: center;
    width: 20%;

    a {
      font-size: 34px;
      transition: .3s all;
      background-color: transparent !important;
      color: white;
      line-height: 31px !important;

      &:hover {
        color: $link-color-hover !important;
        background-color: transparent !important;

      }
    }
  }
}

#product-list {
  margin-top: 50px;

  article {
    padding: 30px;
    text-align: center;

    &:after {
      content: '\A';
      position: absolute;
      width: 100%;
      height: 100%;
      top:0;
      left:0;
      background:rgba(33,102,145,0.3);
      opacity: 0;
      transition: all 1s;
      -webkit-transition: all 1s;
    }

    &:hover:after {
      opacity: 1;
      cursor: pointer;
    }

    img {
      max-width: 170px;
      margin: 0 auto;
    }

    h4 {
      margin-top: 20px;
      color: $product-text-color;
      font-family: "Alright Sans Bold";

    }

    p {
      color: $product-text-color;
      font-family: "Alright Sans Thin";
      font-size: 18px;
    }

  }
}


@media (max-width: 992px){
  .nav-categories {
    a {
      font-size: 23px !important;
    }
  }
}

@media (max-width: 767px) {
  .nav-categories {
    ul {
      width: 100%;
      li {
        width: 100%;
        a {
          text-align: center;
        }
      }
    }

  }
}