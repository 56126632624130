header {
  background-color: $header-bg;
  font-family: $header-font;
  height: 420px;

  #flags {
    display: none;
    position: absolute;
    left: 110px;
    top: -3px;
    z-index: 500;
    width: 100%;

    a {
      width: 100%;
      padding: 5px;

      &:hover {
        text-decoration: none;
      }
    }

  }
  .show-flag {
    display: block !important;
  }

  .top-text {
    a {
      font-size: 27px;
      color: $link-color-hover;
      padding: 5px 14px;
    }

  }

  .logo {
    margin: 60px auto;
    max-height:157px;
  }

  .flags {
    margin-top: 15px;
    margin-right: 30px;
  }

  .navbar-collapse { padding: 0; }
  .navbar-nav { display: table; width: 100%; margin: 0; }
  .navbar-nav > li { display: table-cell; text-align: center; float: none; }

  .navbar-default {
    background: transparent !important;
    border: none;

    .navbar-toggle {
      background-color: #f5f6f7;
    }

    .navbar-collapse {
      text-align: center;
    }

    .active {
      a {
        color: $link-color-hover !important;
        background-color: transparent !important;
      }
    }
    li {
      margin-right: 40px;

      a {
        font-size: 42px;
        color: $link-color !important;
        transition: .3s all;
        &:hover {
          color: $link-color-hover !important;
        }
      }
    }
  }
}

.carousel {
  max-height: 400px;
  overflow: hidden;

  .arrow {
    width: 80px;
    height: 90px;
    background-size: 80px 90px;
    display: block;
    top: 40%;
    position: absolute;
  }

  .arrow-left {
    background-image: url(/images/arrow-left.png);
    margin-left: 38px;
  }
  .arrow-right {
    background-image: url(/images/arrow-right.png);
    margin-right: 38px;
    right: 0;
  }

  .item img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 767px) {
  header {
    height: 285px;

    .logo {
      margin: 0;
      margin-top: 20px;
    }

    .navbar-collapse {
      margin-bottom: -20px;
      padding-bottom: 20px;
    }

    .top-text li a {
      font-size: 27px !important;
    }
    ul {
      display: block;
    }
    li {
      display: block !important;
      a {
        font-size: 20px !important;
      }
    }
  }

  .navbar-nav {
    overflow: hidden;
  }

  .navbar-collapse {
    background-color: #f5f6f7 !important;
    li {
      margin-left: 40px;
    }
  }
  .carousel {
    .arrow {
      background-size: 40px 50px;
      width: 40px;
      height: 50px;
    }
  }
}