#contact {
  background: url(/images/contact-bg.png) no-repeat;
  background-size: 100%;
  min-height: 550px;
  padding: 20px 40px;
  margin: 20px 10px;
  font-family: "Alright Sans Light";
  color: $contact-text;

  form {
    margin-top: 20px;

    .btn-submit {
      margin-top: 26px;
      background-color: $contact-submit-btn-bg;
      border-radius: 16px;
      padding: 7px 25px;
      color: white;
      font-family:"Alright Sans Bold";
    }
  }

  .info-wrapper {
    margin-top: 15%;

    strong {
      display: block;
      font-size: 20px;
      font-family: "Alright Sans Bold";
    }
    a {
      color: $contact-submit-btn-bg;
    }
    addr {
      color: $contact-submit-btn-bg;
    }
  }

}

@media (max-width: 1200px) {
  #contact {
    background: none;
    background-color: $contact-resp-bg;
  }
}

@media (max-width: 990px) {
  #contact {
    .info-wrapper {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid ghostwhite;
    }
  }
}