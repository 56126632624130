.content {
  background-color: $body-bg;
}

h1 {
  margin: 0;
  font-family: "Happy Fox Condensed";
  font-size: 65px;
  color: $link-color;
  padding-bottom: 20px;
}

h2 {
  color: $link-color;
  font-family: "Alright Sans Bold";
  margin-top: 5px;
}

.cloud {
  width: 100%;
}

#how {
  padding-top: 87px;

  .cloud-inner {
    background-color: #f4f4f4;

    .single-how {
      .icon {
        width: 120px;
        height: 120px;
      }

      p {
        padding: 10px 49px;
        color: $link-color;
      }

    }

  }

}

#newArrivals {
  margin-top: 80px;
  padding-bottom: 80px;

  #products-carousel {
    list-style: none;
    height: 300px;
    margin-top: 48px;

    .row {
      width: 75%;
      margin-left: 13%;
    }
    .carousel-control {
      background: none;
      opacity: 1;
    }
    .arrow {
      width: 50px;
      height: 60px;
      background-size: 50px 60px;
    }

    article {
      height: 300px;

      img {
        max-height: 200px;
        margin-bottom: 10px;
      }
      .description {
        bottom: 0;
        position: absolute;
      }
      a {
        &:hover {
          text-decoration: none;
        }
      }
      .price {
        color: #101010;
      }
      p {
        color: #acbbc9;
      }
    }
  }
}

#partners {
  margin-bottom: -35px;

  .partners-inner {
    background-color: $partners-bg;
    padding: 80px 0;
    min-height: 200px;

    #partners-carousel {
      .row {
        width: 75%;
        margin-left: 13%;
      }
      .carousel-control {
        background: none;
        opacity: 1;
      }
      .arrow {
        width: 50px;
        height: 60px;
        background-size: 50px 60px;
      }
      img {
        max-height: 200px;
        margin-bottom: 10px;
      }
    }
  }
}

#reviews {
  .review-inner {
    background-color: $reviews-bg;
    padding: 50px 0;
    height: 800px;

    h1 {
      color: white;
    }

    .review {
      background-image: url(/images/cloud%20txt.png);
      background-size: cover;
      max-width: 600px;
      height: 256px;
      position: relative;
      float: left;

      p {
        color: $reviews-text;
        padding: 52px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  #newArrivals {
    padding-bottom: 100px;
  }
  #newArrivals #products-carousel {
    article {
      height: 250px !important;
      p {
        font-size: 12px;
      }
      .description {
        bottom: 34px;
      }
    }
    .row {
      width: 100% !important;
      margin-left: -10px !important;
      margin-right: 0;
    }
    .arrow {
      width: 30px !important;
      height: 40px !important;;
      background-size: 30px 40px !important;
      top: 74%;
    }
  }
  #reviews {
    .review-inner {
      .review {
        p {
          font-size: 10px;
        }
      }
    }
  }
}