#about-page {
  article {
    margin: 60px 30px;
    p {
      padding-top: 20px;
      font-family: "Alright Sans Regular";
      color: $link-color;
    }
  }
}

#about-page h2, #partners h2, #about h2, #faq h2 {
  font-family: "Happy Fox Condensed";
  font-size: 50px;
}
#about-page, #faq, #partners {
  margin-top: 30px;
}