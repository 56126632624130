.dropdown {
  a {
    color: red;
    &:hover, &:active, &:focus {
      background: none !important;
    }
  }
}

ul.dropdown-cart{
  min-width:250px;
  margin-top: 7px !important;
}
ul.dropdown-cart li .item{
  display:block;
  padding:3px 10px;
  margin: 3px 0;
  font-size: 20px;
}
ul.dropdown-cart li .item:hover{
  background-color:#f3f3f3;
}
ul.dropdown-cart li .item:after{
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

ul.dropdown-cart li .item-left{
  float:left;
}
ul.dropdown-cart li .item-left img,
ul.dropdown-cart li .item-left span.item-info{
  float:left;
}
ul.dropdown-cart li .item-left span.item-info{
  margin-left:10px;
}
ul.dropdown-cart li .item-left span.item-info span{
  display:block;
}
ul.dropdown-cart li .item-right{
  float:right;
}
ul.dropdown-cart li .item-right button{
  margin-top:14px;
}
.nav .open > a {
  background: none;
}

@media (max-width: 767px) {
  .top-text {
    .nav {
      position: relative;
      width: 100%;
    }
  }
  .dropdown-cart {
    z-index: 200;
    background-color: white !important;
    position: absolute !important;
  }

  .cart-menu {
    position: absolute !important;
    width: 100%;
    text-align: right;

  }
}