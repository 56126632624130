#faq {
  h2 {
    margin-bottom: 30px;
  }
  article {
    font-family: "Alright Sans Regular";
    margin: 50px 30px;
    cursor: pointer;

    .indicator {
      background-image: url(/images/faq-indicator-blue.png);
      background-repeat: no-repeat;
      width: 51px;
      height: 51px;
      margin: 10px auto;
      opacity:.6;
      -webkit-transition: opacity 0.2s ease-in-out;
      transition: opacity 0.2s ease-in-out;
    }

    p {
      font-weight: 700;
      font-size: 15px;
    }

    &:hover, &:active {
      .indicator {
        opacity: 1;
        background-image: url(/images/faq-indicator-purple.png);
      }
    }
  }

  .collapse, .collapsing {
    p {
      margin: -21px 30px;
      font-family: "Alright Sans Regular";
      color: $link-color;
    }
  }
  .collapsing {
    transition: height 0.6s;
  }

  .well {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}