footer {
  height: 400px;
  background-color: $footer-bg;
  padding-top: 70px;

  .logo {
    max-height: 150px;
    margin: 0 auto;
  }
  .social {
    margin-top: 30px;
    padding-left: 75px;
    a {
      float: left;
      display: inline-block;
      margin-right: 10px;
    }
    img {
      margin: 0 auto;
      max-width: 70px;
      height: auto;
      &:hover {
        -webkit-animation-name: shake;
        animation-name: shake;
      }
    }
  }

  .footer-menu {
    li {
      list-style: none;
      margin-bottom: 26px;
      a {
        font-family: "Alright Sans Bold";
        font-size: 17px;
        color: $link-color;
        text-decoration: none;
        transition: all ease-in-out .2s;

        &:hover {
          color: $link-color-hover;
        }
      }
    }
  }
}

@media (max-width: 992px){
  .social {
    padding-left: 23px !important;
    img {
      max-width: 40px !important;
    }
    a {
      margin-bottom: 5px;
    }
  }
  footer {
    ul {
      padding: 0;
      a {
        font-size: 9px !important;
      }
    }
  }
}