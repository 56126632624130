body {
  background: url('/images/bg-clouds.png') repeat-y;
  background-size: 100%;
}

a {
  outline: 0;
}
a:hover, a:active, a:focus {
  outline: 0;
}

.cart {
  background: url(/images/cart.png) no-repeat;
  background-size: 32px;
  content: " ";
  width: 33px;
  height: 27px;
  display: inline-block;
}