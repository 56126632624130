$header-bg: #f5f5f5;
$link-color: #20638e;
$link-color-hover: #ab2571;

$header-font: 'Happy Fox Condensed';

$body-bg: #fff;

$main-cart-color: #6394F8;
$light-cart-text: #ABB0BE;

$partners-bg: #f5f6f7;

$reviews-bg: #22668f;
$reviews-text: #4e7c9e;

$footer-bg: #f5f6f7;

$product-text-color: #216691;

$modal-h-text: #22668f;
$modal-p-text: #216691;

$contact-text: #ffffff;
$contact-resp-bg: #70c7d1;
$contact-submit-btn-bg: #ab2571;

@font-face {
  font-family: "Happy Fox Condensed";
  src: url(/fonts/HappyFox-Condensed.otf);
}

@font-face {
  font-family: "Alright Sans Bold";
  src: url(/fonts/AlrightSans-Bold-v3_0.otf);
}

@font-face {
  font-family: "Alright Sans Thin";
  src: url(/fonts/AlrightSans-Thin-v3_0.otf);
}

@font-face {
  font-family: "Alright Sans Regular";
  src: url(/fonts/AlrightSans-Regular-v3_0.otf);
}

@font-face {
  font-family: "Alright Sans Light";
  src: url(/fonts/AlrightSans-Light-v3_0.otf);
}

@-webkit-keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}