#partners {
  p {
    color: $link-color;
    font-family: "Alright Sans Light";
    margin: 20px 30px;
  }
}

#partners-list {
  margin-top: 80px;
  padding-bottom: 50px;
  article {
    padding: 30px;
    img {
      margin: 0 auto;
    }
  }
}