.modal {
  font-family: "Alright Sans Thin";

  a {
    font-size: 20px;
  }
  .modal-header {
    border: none;
  }

  h3 {
    color: $modal-h-text;
    font-family: "Alright Sans Bold";
  }
  p {
    color: $modal-p-text;
  }

  #product-slider {
    height: 250px;
    margin-top: 50px;
    margin-bottom: 50px;

    .carousel-control {
      background: none;
      opacity: 1;
    }
    .arrow {
      width: 50px;
      height: 60px;
      background-size: 50px 60px;
    }
    img {
      max-width: 200px;
      margin: 10px auto;
    }
  }

  .price {
    display: inline-block;
    float: left;
  }
}